<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div style="display: flex;">
        <div class="count">支付金额: {{ payCount.payAmount }}</div>
        <div class="count">退款金额: {{ payCount.refundAmount }}</div>
      </div>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('export') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                          background-color: #2362FB; border-color: #2362FB"
            @click="exportExcel"
            >导出
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                            background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                            background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click="getInfo(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              v-if="'status' == column.column_prop"
              class="switch"
              @change="changeStatus(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-date-picker
                v-if="'pay_time' == column.column_prop"
                v-model="searchParams.pay_time"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.pay_time)
                "
              >
              </el-date-picker>
              <el-date-picker
                v-if="'refund_time' == column.column_prop"
                v-model="searchParams.refund_time"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.refund_time)
                "
              >
              </el-date-picker>
              <el-select
                v-if="'status_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.status_name)
                "
                v-model="searchParams.status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'pay_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.pay_sn"
                clearable
                @change="handleSearch(column.column_prop, searchParams.pay_sn)"
              >
              </el-input>
              <el-input
                v-if="'transaction_id' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.transaction_id"
                clearable
                @change="handleSearch(column.column_prop, searchParams.transaction_id)"
              >
              </el-input>
              
              <el-input
                v-if="'order_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.order_sn"
                clearable
                @change="handleSearch(column.column_prop, searchParams.order_sn)"
              >
              </el-input>
              <el-input
                v-if="'member_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.member_name)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { downloadFile } from "@/utils/common";
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import {getPayList, getPayCount, getExport, postExport} from "@/newApi/fina";
import { Message } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "financialManage",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
    this._getPayCount();
  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  computed:{
    ...mapState({
      table_options: state => state.user.table_options,
    })

  },
  data() {
    return {
      table_type: "financialManage",
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "pay_time",
          isSearch: false,
        },
        {
          name: "status_name",
          isSearch: false,
        },
        {
          name: "refund_time",
          isSearch: false,
        },
        {
          name: "pay_sn",
          isSearch: false,
        },
        {
          name:"transaction_id",
          isSearch: false,
        },
        {
          name:'order_sn',
          isSearch:false
        },
        {
          name: "type",
          isSearch: false,
        },
        {
          name: "member_name",
          isSearch: false,
        },
        {
          name: "phar_id",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "pay_time",
        "refund_time",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
      options: [
        { value: 1, label: "待支付" },
        { value: 2, label: "已支付" },
        { value: 3, label: "支付失败" },
        { value: 4, label: "退款中" },
        { value: 5, label: "已退款" },
      ],
      typeList: [
        { value: 1, label: "处方购药" },
        { value: 2, label: "商城购买" },
        { value: 3, lebel: "客情订单" },
      ],
      payCount: {},
    };
  },
  components: {
    Pagination,
    editTableHead,
  },

  methods: {
    _getPayCount() {
      getPayCount().then((res) => {
        this.payCount = res.data;
      });
    },
    //导出
    exportExcel() {
      this.listLoading = true;
      let params = Object.assign({}, this.searchParams);
      console.log(params,222)
      getExport(params).then((response) => {
        console.log(response);
        const href = URL.createObjectURL(response); //创建新的URL表示指定的blob对象
        const a = document.createElement("a"); //创建a标签
        a.style.display = "none";
        a.href = href; // 指定下载链接
        a.download = "交易明细表"; //指定下载文件名
        a.click();
      });
      this.listLoading = false;
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "financialManage",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.financialManage;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;

      getPayList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
.count {
  margin-left: 20px;
  font-size: 12px;
  padding: 3px 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
</style>
